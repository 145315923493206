import {openDialog as openAddPersonDialog} from "./cbar-add-person.module";
import * as timeSchedule from '@cbar/jq.schedule';
import 'jquery-ui/dist/jquery-ui';

var eventId = $(location).attr("search");
var scheduleDisplay;
var rowsToDisplay;

if (eventId.match(/\?id=(\d*)/)) {
    eventId = eventId.match(/\?id=(\d*)/)[1];
} else if (window.location.pathname.toLowerCase().match(/\/events\/view\/([0-9]+)/)) {
    eventId = window.location.pathname.toLowerCase().match(/\/events\/view\/([0-9]+)/)[1];
}

function getQueryParams() {
    let queryParams = window.location.search.substr(1);
    if (!queryParams) {
        return {};
    }

    queryParams = queryParams.split('&');

    let returnObj = {};
    for (let keyValue of queryParams) {
        // Param is a key=value string
        let [key, value] = keyValue.split('=');

        returnObj[key] = decodeURIComponent(value);
    }

    return returnObj;
}

const queryParams = getQueryParams();
let sort = queryParams.sort;
let filter = queryParams.filter;

var $fab = $('.roster-add-participant');

var rosterUpdateRoleDialog;
var rosterEditDialog;

var isMassPrintRosterPage = window.location.pathname.toLowerCase() === '/events/printrosters';
var isPrintRosterPage = isMassPrintRosterPage || $("body").is("#print_roster");
var isEventsPage = !isPrintRosterPage && !isMassPrintRosterPage && $("body").is("#events");

function animateFab($fab) {
    $fab.addClass('transition-left');
}

$(function () {
    "use strict";
    rosterUpdateRoleDialog = document.querySelector('cc-update-role#roster-update-role-dialog');

    // Fill in the roster data on page load of an event or print roster page
    // Set Team role dropdown options based on existing profile relationships for the route EventsController:indexAction()
    if (isEventsPage) {
        var paperTabs = document.querySelector('paper-tabs');

        if (paperTabs) {
            // RENDER ISSUES WITHOUT THIS
            paperTabs.addEventListener('selected-changed', async function (e) {
                if (paperTabs.selected === 'roster') {
                    loadRosterChart(1175);
                }
            })
        }

        window.addEventListener('hash-tab-selected', () => {
            const $activeFab = $('iron-pages > .iron-selected').find('paper-fab');
            if ($activeFab.length) {
                // Fab is already on the page, animate it.
                window.setTimeout(function () {
                    animateFab($fab);
                }, 100);
            }
        });

        $(".transition-left-button").on("tap", function () {
            window.setTimeout(function () {
                animateFab($fab);
            }, 100);
        });

        $fab.on('click', function () {
            openAddPersonDialog({eventId: eventId});
        });

        $(window).on('scroll', function () {
            window.requestAnimationFrame(function () {
                // Find an active tab with a descendant FAB.
                var $activeFab = $('.active').find('paper-fab');

                if (!$activeFab.length) {
                    return;
                }

                var $activeTab = $activeFab.closest('paper-card');

                // Get the offset of our tab's contents relative to the page.
                var contentOffset = $activeTab.position().top;
                var scrollOffset = document.documentElement.scrollTop || document.body.scrollTop;

                // The max top offset should be the size of the tab's content
                var maxTopOffset = $activeTab.height();

                var topOffset = 50;
                if (scrollOffset > contentOffset) {
                    topOffset = Math.min(scrollOffset - contentOffset + 50, maxTopOffset);
                }

                $activeFab.css({top: topOffset});
            });
        });

        $('.print-roster-link').on('click', function (e) {
            const eventId = $("input[name='id']").val();
            let sortType = "";
            const sortTypeElement = $("paper-dropdown-menu[name='sort-option']")[0];

            if (sortTypeElement) {
                sortType = sortTypeElement.contentElement.selected;
            } else {
                return;
            }

            const filter = getRoleFilter();
            const link = `/print_roster?id=${eventId}&sort=${sortType}&filter=${filter}`;

            window.open(location.origin + link);
        });

        $(rosterUpdateRoleDialog).on('role-removed role-updated', function (e) {
            rosterUpdateRoleDialog.close();

            if (rosterUpdateRoleDialog.updatingFromSchedule) {
                let result = e.detail.result;
                if (result.error) {
                    return;
                }

                let updatedRole = result.data;

                // dont need to reload, the ui has updated already, just save for resettable data
                var selectedFilter = $('[name=sort-option]')[0].contentElement.selected;

                var scheduleData = scheduleDisplay.timeSchedule('scheduleData');
                var index = scheduleData.map(role => role.data.RelationshipID).indexOf(updatedRole.RelationshipID.toString());

                scheduleData[index].start = updatedRole.start_time;
                scheduleData[index].end = updatedRole.end_time;
                scheduleData[index].data = updatedRole;

                var roleData = scheduleData.map(function (data) {
                    return {...data.data, start_time: data.start, end_time: data.end}
                });

                rowsToDisplay = formatRolesForLibrary(roleData, selectedFilter === "person-name");

                scheduleDisplay.timeSchedule('setRows', rowsToDisplay);

                return;
            }

            reloadParticipants();
        });


        $(rosterUpdateRoleDialog).on('cancel-pressed', function () {
            rosterUpdateRoleDialog.close();
        });
    } else if (isPrintRosterPage) {

        $("#cbar-header").hide();
        $("footer").last().hide();
        $(".rendered_time").hide();
        $(".tabs ul").first().hide();
        $(".chart_filters").first().hide();

        // Remove our margin that accounts for the usual header space as we are hiding the header.
        $(".groundwork-namespace").css({"margin-top": "0"});

        if (isMassPrintRosterPage) {
            loadMassRosterCharts(1175);
        } else {
            loadRosterChart(1175);
        }
    }
});

export function reloadParticipants() {
    // Reload our roster.
    clearRosterChart();
    loadRosterChart(1175);
    $("#participant_submit_search").click();
}

export function loadRosterChart(chartWidth) {
    "use strict";

    if (!eventId) {
        return;
    }

    // Show the spinner
    $('.roster-spinner').show();


    // Get roster data
    d3.json("/ajax/event-roster?event_id=" + eventId, function (error, roles) {

        $('.roster-spinner').hide();
        /**
         * not allowed to print chart
         */
        if (roles.error) {
            $('.chart-display-error').html(roles.message);
            // we need to set the innerHTML of something or show something on the page
            return;
        }

        if (typeof roles !== "undefined" && roles.length > 0) {
            $(".chart_filters").first().show();

            if (isPrintRosterPage) {
                // For the print page, only create the chart to fit
                // the selected roles.
                roles = getSelectedRoles(roles);
            }

            createRosterChart(roles, chartWidth, true);
        } else {
            $(".chart_filters").first().hide();
        }

        // Toggle print dialog
        if ($("body").is("#print_roster")) {
            setTimeout(window.print, 1000);
        }
    });
}

function loadMassRosterCharts(chartWidth) {
    "use strict";

    // Displaying multiple rosters is used in print_rosters.php, filtered by
    // GET variables. event-roster.php takes the same GET variables as print_rosters.php
    // e.g. if the current URL is "/print_rosters?group_id=53716&search_string=..."
    // pass the same "?group_id=53716&search_string=..." to event-roster.php.
    var getParameters = $(location).attr("search");

    // Get roster data for all future events owned by group.
    d3.json("/ajax/event-roster" + getParameters, function (error, rosters) {
        // For each event, insert SVG elements to the corresponding event
        // summary content_box and fill the roster data within the SVG elements.
        $.each(rosters, function (eventId, roles) {
            // The event summary content_box element to append the SVG elements.
            var eventSummary = $('.current-event-id:contains(' + eventId + ')')
            .closest('.content_box');

            // Remove loading spinner
            var $spinner = eventSummary.nextAll('.roster-spinner').first();
            $spinner.remove();

            if (roles.error || roles.length === 0) {
                var message = roles.error ? roles.message : 'No roster available for event';
                // Create error div
                var errorDiv = $('<div>')
                .addClass('roster-error')
                .html(message);

                eventSummary.after(errorDiv);
                return;
            }

            // The SVG element(s) to insert.
            var svgElement = '' +
                '<div class="svg-wrap-">' +
                '    <svg class="unprocessed-chart roster-' + eventId + '" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"></svg>' +
                '</div>';

            // Calculate the number of pages will be required
            // to print the event roster on A4 paper.
            // The first page can display less roles than subsequent pages
            // because of the space taken up by the event summary.
            var rolesFirstPage = 12;
            var rolesPerPage = 17;
            var eventRolesCount = roles.length;
            var pagesToPrint;

            if (eventRolesCount <= rolesFirstPage) {
                pagesToPrint = 1;
            } else {
                pagesToPrint = 1 + parseInt(Math.ceil((eventRolesCount - rolesFirstPage) / rolesPerPage));
            }

            // Insert an SVG element for each required page.
            for (var page = 0; page < pagesToPrint; page++) {
                // Attach a page break between SVG elements
                if (page !== pagesToPrint - 1) {
                    $(svgElement).addClass("page-break-after");
                }
                eventSummary.after(svgElement);
            }

            // Fill the SVG elements with roster data.
            createRosterChart(roles, chartWidth);

            // Remove any remaining spinners (for events without roster results)
            $('.roster-spinner').remove();
        });
    });
}

export function clearRosterChart() {
    d3.selectAll("svg[class^='chart-']").attr("class", "unprocessed-chart").selectAll("*").remove();

    if (scheduleDisplay) {
        scheduleDisplay.timeSchedule('resetData');
        scheduleDisplay.hide();
    }
}

function getRoleFilter() {
    const allRoles = "All Positions";

    if (isPrintRosterPage) {
        // On the print page, we use the "filter" parameter in the query.
        return filter || allRoles;
    }

    const roleFilter = d3.select("#role-filter");
    if (roleFilter.size() > 0) {
        return roleFilter[0][0].selected;
    }

    return allRoles;
}

function getSelectedRoles(roles) {
    const selectedFilter = getRoleFilter();
    if (selectedFilter === "All Roles") {
        return roles;
    }

    return roles.filter(role =>
        role.role_name === selectedFilter);
}

function createRosterChart(roles, chartWidth, restrictDisplay = false) {
    var exampleRole = roles[0];

    if (!isPrintRosterPage) {
        if (scheduleDisplay) {
            $('.roster-display').empty();
        }

        let startTimeObject = moment(exampleRole.min_starttime);
        let startTime = startTimeObject.format('HH:mm');

        let endTimeObject = moment(exampleRole.max_endtime);
        let endTime = endTimeObject.isSame(startTimeObject, 'day') ? endTimeObject.format("HH:mm") : "24:00";

        initializeScheduleDisplay(startTime, endTime, formatRolesForLibrary(roles, true));

        initializeRoleFilter(getUniqueRoleNames(roles));

        if(scheduleDisplay){
            scheduleDisplay.show();
        }

    } else {
        roles = setupRosterRoles(roles, restrictDisplay);
        initalizeRoster(chartWidth, roles);
    }


    $('[name=sort-option]').on("selected-item-changed", function (e) {
        // stops double firing between selection changing
        if (e.detail.value === null) {
            return;
        }

        var selectedRoles = getSelectedRoles(roles);
        sortRoles(selectedRoles);
    });

    $("#role-filter").on("selected-item-changed", function (e) {
        // stops double firing between selection changing
        if (e.detail.value === null) {
            return;
        }

        var selectedRoles = getSelectedRoles(roles);
        sortRoles(selectedRoles);
    });
}

function formatRolesForLibrary(roles, group = false) {
    setRolesTiming(roles);
    return group ? formatRolesWithGrouping(roles) : formatRolesWithoutGrouping(roles)
}

function formatRolesWithGrouping(roles) {
    let rolesToAdd = {}
    let groupedRoles = {};

    roles.forEach(function (role) {
        // if the role ends before the start
        let roleIsTooLate = moment(role.min_starttime).isAfter(role.end_datetime);
        // or starts after the end time
        let roleIsTooEarly = moment(role.max_endtime).isBefore(role.start_datetime);

        // return
        if (roleIsTooLate || roleIsTooEarly) {
            return;
        }

        if (!groupedRoles[role.ProfileID]) {
            groupedRoles[role.ProfileID] = [];
        }

        groupedRoles[role.ProfileID].push(role)
    });

    Object.keys(groupedRoles).forEach(function (key, i) {
        let targetedRoles = groupedRoles[key];
        let title;
        let subtitle;
        let schedule = [];

        targetedRoles.forEach(function (role, i) {

            if (moment(role.end_datetime).isAfter(moment(role.max_endtime))) {
                let roleIsSameDay = moment(role.end_datetime).isSame(moment(role.start_datetime), 'day');
                role.end_time = roleIsSameDay ? moment(role.max_endtime).format('HH:mm') : "24:00";
            }

            // if it starts before the starttime for the event, cut it there
            if (moment(role.start_datetime).isBefore(moment(role.min_starttime))) {
                role.start_time = moment(role.min_starttime).format('HH:mm');
            }

            title = `${role.first_name} ${role.last_name}`;
            subtitle = `${role.mobile ? role.mobile : ''}`;

            role.class = role.role_name.toLowerCase() + '-bar';

            schedule.push({
                start: role.start_time,
                end: moment(role.end_datetime).isSame(moment(role.start_datetime), 'day') ? role.end_time : "24:00",
                text: role.role_name,
                // include whole role as data here so we can use in update functions
                data: role
            });
        })

        rolesToAdd[i] = {
            title,
            subtitle,
            schedule
        }
    });

    return rolesToAdd;
}

function formatRolesWithoutGrouping(roles) {
    let rolesToAdd = {}

    roles.forEach(function (role, i) {
        // if the role ends before the start
        let roleIsTooLate = role.min_starttime > role.end_datetime;
        // or starts after the end time
        let roleIsTooEarly = role.max_endtime < role.start_datetime;

        // return
        if (roleIsTooLate || roleIsTooEarly) {
            return;
        }


        if (moment(role.end_datetime).isAfter(role.max_endtime)) {
            let roleIsSameDay = moment(role.end_datetime).isSame(moment(role.start_datetime), 'day');
            role.end_time = roleIsSameDay ? moment(role.max_endtime).format('HH:mm') : "24:00";
        }

        // if it starts before the starttime for the event, cut it there
        if (moment(role.start_datetime).isBefore(role.min_starttime)) {
            role.start_time = moment(role.min_starttime).format('HH:mm');
        }

        role.class = role.role_name.toLowerCase() + "-bar";
        rolesToAdd[i] = {
            title: `${role.first_name} ${role.last_name}`,
            subtitle: `${role.mobile ? role.mobile : ''}`,
            schedule: [
                {
                    start: role.start_time,
                    end: role.end_time,
                    text: role.role_name,
                    // include whole role as data here so we can use in update functions
                    data: role
                }
            ]
        }
    });

    return rolesToAdd;
}

function initializeScheduleDisplay(startTime, endTime, rolesToAdd) {
    var $rosterDisplay = $('.roster-display');

    scheduleDisplay = $rosterDisplay.timeSchedule({
        startTime: startTime, // schedule start time(HH:ii)
        endTime: endTime,   // schedule end time(HH:ii)
        widthTime: 60 * 30,  // cell timestamp example 10 minutes
        timeLineY: 60,       // height(px)
        verticalScrollbar: 20,   // scrollbar (px)
        timeLineBorder: 2,   // border(top and bottom)
        bundleMoveWidth: 6,  // width to move all schedules to the right of the clicked time line cell
        draggable: !isPrintRosterPage,
        resizable: !isPrintRosterPage,
        resizableLeft: !isPrintRosterPage,
        rows: rolesToAdd,
        onChange: function (node, data) {
            if (!isPrintRosterPage) {
                let roleData = data.data;

                let splitStartTime = data.start.split(":");
                let splitEndTime = data.end.split(":");

                // manipulate the role data with the new time
                let newStartTime = moment(roleData.min_starttime).hour(parseInt(splitStartTime[0])).minute(parseInt(splitStartTime[1]));
                let newEndTime = moment(roleData.max_endtime).hour(parseInt(splitEndTime[0])).minute(parseInt(splitEndTime[1]));

                roleData.StartTime = newStartTime.format("DD-MM-YYYY HH:mm");
                roleData.EndTime = newEndTime.format("DD-MM-YYYY HH:mm");

                rosterUpdateRoleDialog.roleToUpdate = null;

                requestAnimationFrame(() => {
                    rosterUpdateRoleDialog.roleToUpdate = Object.assign({}, roleData);
                    rosterUpdateRoleDialog.firstName = roleData.first_name;
                    rosterUpdateRoleDialog.lastName = roleData.last_name;
                    rosterUpdateRoleDialog.updatingFromSchedule = true;

                    // Open the update role dialog.
                    rosterUpdateRoleDialog.open();
                })

                rosterUpdateRoleDialog.addEventListener('cancel-clicked', (e) => {
                    this.timeSchedule('setRows', rowsToDisplay);
                }, {once: true})
            }
        },
        onClick: function (node, data) {
            if (!isPrintRosterPage) {
                let roleData = data.data;

                rosterUpdateRoleDialog.roleToUpdate = Object.assign({}, roleData);
                rosterUpdateRoleDialog.firstName = roleData.first_name;
                rosterUpdateRoleDialog.lastName = roleData.last_name;
                rosterUpdateRoleDialog.updatingFromSchedule = false;
                rosterUpdateRoleDialog.disabled = false;

                // Open the update role dialog.
                rosterUpdateRoleDialog.open();
            }
        },
        onAppendRow(node, role) {
            var data = role.data;
            node.addClass(data.class);
        }
    });
}

function setupRosterRoles(roles, restrictDisplay) {
    roles.forEach(function (role, i) {
        // #17344
        if (restrictDisplay) {
            // check if user is before / after the event, if so, dont display
            role.start_datetime = role.start_datetime.replace("T", " ");
            role.end_datetime = role.end_datetime.replace("T", " ");
            if (role.start_datetime > role.max_endtime || role.end_datetime < role.min_starttime) {
                roles.splice(i, 1);
                return;
            }

            // if they have a role that leads out of the event time, cut it at that point
            if (role.end_datetime > role.max_endtime) {
                role.end_datetime = role.max_endtime;
            }

            // if it starts before the starttime for the event, cut it there
            if (role.start_datetime < role.min_starttime) {
                role.start_datetime = role.min_starttime;
            }
        }
    });
    setRolesTiming(roles)
    return roles;
}

function mapYLabel(role, index) {
    var roleName = parseInt(role.status_id) === 2 ? "Invited " + role.role_name : role.role_name;
    var labelStrings = [role.person_id, role.first_name, role.last_name, role.mobile, roleName, role.start_time, role.end_time, index];
    var label = "";
    labelStrings.forEach(function (string, index, array) {
        label += string !== undefined && string !== null ? string + ";" : ";";
    });
    return label;
}

// Create helper: Perform display update/refresh of roster chart
function drawRosterBars(roles, chart, barHeight, xScale, yScale, yAxis) {
    // Set data
    var bar = chart.selectAll(".bar").data(roles);

    // Create new bars
    bar.enter().append("rect").attr("class", function (role) {
        var pending = parseInt(role.status_id) === 2;
        return pending ? "pending-invitation bar" : "bar";
    }).attr("height", barHeight - 1);

    // Remove bars when processing is finished
    bar.exit().remove();

    // Display the updated bars
    var transition = chart.transition().duration(500);
    transition.selectAll(".bar").attr("class", function (role) {
        var pending = parseInt(role.status_id) === 2;
        return pending ? "pending-invitation bar" : "bar ";
    }).attr("width", function (role) {
        return xScale(role.end_in_mins) - xScale(role.start_in_mins);
    }).attr("transform", function (role, index) {
        var x = xScale(role.start_in_mins);
        var y = index * barHeight;
        return "translate(" + x + ", " + y + ")";
    });

    // Animate the y axis
    transition.select(".y.axis").call(yAxis).selectAll("text").call(arrangeLabel, yScale.rangeBand());
}

function arrangeLabel(labels, width) {

    labels.each(function () {
        var label = d3.select(this);
        var labelComponents = label.text().split(";");

        // XXX: Weird bug that doesn't detect separators during transition period
        // of filtering data from drop down menu (#role-filter).
        // Must manually create empty strings in the interim.
        // Words display fine after the transition period is over.
        for (var i = labelComponents.length; i < 7; i++) {
            labelComponents.push("");
        }

        var profileId = labelComponents[0].trim();
        var firstName = labelComponents[1].trim();
        var lastName = labelComponents[2].trim();
        var mobile = labelComponents[3].trim() === "" ? " " : labelComponents[3].trim();
        var roleName = labelComponents[4].trim();
        var startTime = labelComponents[5].trim();
        var endTime = labelComponents[6].trim();


        label.text(null);
        var firstLine = label.append("a").attr({"xlink:href": "/profiles/view/" + profileId});
        firstLine.append("tspan").text(firstName + " " + lastName).attr("x", -10).attr("dy", "-0.5em").attr("class", "line-one person-name-label");

        var secondLine = label.append("tspan");
        secondLine.text(roleName + " (" + startTime + " - " + endTime + ")").attr("x", -10).attr("dy", "1.2em").attr("class", "line-two role-details-label");

        var thirdLine = label.append("tspan");
        thirdLine.text(mobile).attr("x", -10).attr("dy", "1.2em").attr("class", "line-three person-mobile-label");
    });
}

// Create helper: update and filter based on input selection
function updateSortAndFilter(roles, wrapperList, rolesPerPage, rolesFirstPage, barHeight, xScale, yScale, yAxis) {
    var rolesPrinted = 0;
    wrapperList.forEach(function (wrapper, i) {

        var chart = wrapper;
        var selectedRoles = getSelectedRoles(roles);
        sortRoles(selectedRoles);

        if (isPrintRosterPage) {
            const length = i === 0 ? rolesFirstPage : rolesPerPage;
            selectedRoles = selectedRoles.slice(rolesPrinted, rolesPrinted + length);

            rolesPrinted = rolesPrinted + length;
        }

        yScale.rangeRoundBands([0, selectedRoles.length * barHeight], 0, 0)
        .domain(selectedRoles.map((role, i) => mapYLabel(role, i)))
        .copy();

        drawRosterBars(selectedRoles, chart, barHeight, xScale, yScale, yAxis);
    });
}

// Create helper: Sorts roles data in-place
function sortRoles(roles) {
    var sortOption = sort ? sort : $('[name=sort-option]')[0].contentElement.selected;

    roles.sort(function (roleA, roleB) {
        switch (sortOption) {
            case "person-name":
                return (roleA.last_name + " " + roleA.first_name + " " + roleA.role_name + " " + roleA.start_in_mins).toLowerCase() > (roleB.last_name + " " + roleB.first_name + " " + roleB.role_name + " " + roleB.start_in_mins).toLowerCase() ? 1 : -1;
            case "role-name":
                return (roleA.role_name + " " + roleA.last_name + " " + roleA.first_name + " " + roleA.start_in_mins).toLowerCase() > (roleB.role_name + " " + roleB.last_name + " " + roleB.first_name + " " + roleB.start_in_mins).toLowerCase() ? 1 : -1;
            case "start-time":
                if (roleA.start_in_mins !== roleB.start_in_mins) {
                    return roleA.start_in_mins - roleB.start_in_mins;
                }

                return (roleA.last_name + " " + roleA.first_name + " " + roleA.role_name + " " + roleA.start_in_mins).toLowerCase() > (roleB.last_name + " " + roleB.first_name + " " + roleB.role_name + " " + roleB.start_in_mins).toLowerCase() ? 1 : -1;
        }
    });

    if (scheduleDisplay) {
        rowsToDisplay = formatRolesForLibrary(roles, sortOption === "person-name");
        scheduleDisplay.timeSchedule('setRows', rowsToDisplay);
    }
}

function initalizeRoster(chartWidth, roles) {

    // =========================================================================
    // BEGIN: Main logic for displaying event roster.
    // =========================================================================

    // 1. Set the chart's dimensions, min and max times to display (same for each page)
    var margin = {top: 50, right: 50, bottom: 50, left: 250};
    chartWidth = chartWidth - margin.left - margin.right;

    var barHeight = 60;
    var earliestStartMins = d3.min(roles, function (role) {
        return role.start_in_mins;
    });
    var latestFinishMins = d3.max(roles, function (role) {
        return role.end_in_mins;
    });

    var earliestStartDateTime = d3.min(roles, function (role) {
        return role.start_datetime;
    });

    // TODO: extract this from print_roster.php instead of hardcoding
    var rolesFirstPage = 12;
    var rolesPerPage = 17;

    // Process all charts (i.e. for each page of print_rosters)
    var chartsList = d3.selectAll(".unprocessed-chart");
    var pageCount = chartsList[0].length;
    // used in order to filter roles correctly
    var wrapperList = [];
    for (var page = 0; page < pageCount; page++) {
        var chart = chartsList[page];

        // Split the roles if there is more than one page
        // Otherwise, display all the roles on one page
        var rolesThisPage = null;
        if (pageCount > 1) {
            var start;
            var end;
            if (page === 0) {
                start = page * rolesFirstPage;
                end = start + rolesFirstPage;
            } else {
                start = rolesFirstPage + ((page - 1) * rolesPerPage);
                end = start + rolesPerPage;
            }
            rolesThisPage = roles.slice(start, end);
        } else {
            rolesThisPage = roles;
        }

        var chartHeight = (barHeight * rolesThisPage.length);

        // 2. Create the chart
        // TODO: use resize() instead of viewBox() so that we control how text resizes
        // Reference: https://blog.safaribooksonline.com/2014/02/17/building-responsible-visualizations-d3-js/
        chart = d3.select(".unprocessed-chart")
        .attr("viewBox", "0 0 " + (chartWidth + margin.left + margin.right) + " " + (chartHeight + margin.top + margin.bottom))
        .append("g")
        .attr("class", "bar-wrapper")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        wrapperList.push(chart);
        // Pad the beginning and end of the chart by an extra hour
        var earliestHour = (Math.floor(earliestStartMins / 60) - 1) * 60;
        var latestHour = (Math.ceil(latestFinishMins / 60) + 1) * 60;

        // 4a. Scale the roster bars to fill the chart's width
        var xScale = d3.scale.linear()
        .range([0, chartWidth])
        .domain([earliestHour, latestHour]);

        // 4b. Scale the y-axis labels to be centred with roster bars
        var yScale = d3.scale.ordinal()
        .rangeRoundBands([0, chartHeight], 0, 0)
        .domain(rolesThisPage.map((role, i) => mapYLabel(role, i)));

        // 5a. Add x-axis labels on every hour
        var xAxisTop = d3.svg.axis()
        .scale(xScale)
        .orient("top")
        // Hide labels for the first hour and last hour
        .tickValues(d3.range(earliestHour + 60, latestHour, 60))
        .tickSize(6, 0)
        .tickFormat(function (numberOfMinutes) {
            // Convert original Unix timestamp in minutes
            // to timestamp milliseconds for JS Date formatting.
            var date = new Date(numberOfMinutes * 60 * 1000);

            // Pad hour and minutes with a leading 0 if single digit.
            var hh = ("0" + date.getHours()).slice(-2);
            var mm = ("0" + date.getMinutes()).slice(-2);

            return hh + ":" + mm;
        });
        // Move the line up 1px so it doesn't overlap the first bar
        chart.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(" + 0 + "," + (-1) + ")")
        // Make the text horizontal, then re-align to tick marks
        .call(xAxisTop)
        .selectAll("text")
        .attr("transform", "rotate(-90)")
        .attr("dx", "1.8em")
        .attr("dy", "1.1em");

        // 5b. Add y-axis labels for every role
        var yAxis = d3.svg.axis()
        .scale(yScale)
        .tickSize(6, 0)
        .orient("left");
        chart.append("g")
        .attr("class", "y axis");

        // 6. Add vertical gridlines
        var xGrid = d3.svg.axis()
        .scale(xScale)
        .orient("top")
        .tickValues(d3.range(earliestHour, latestHour + 1, 60))
        .tickFormat("")
        // Add the 1px that was padded for the x-axis labels
        .tickSize(chartHeight + 1, 0, 0);
        chart.append("g")
        .attr("class", "x-grid")
        .attr("transform", "translate(" + 0 + "," + (chartHeight) + ")")
        .call(xGrid);

        //Logic for the bottom x-axis (number of people on a given time)
        //Keep a track of what tick number we are up to so we can ensure that we have the
        //correct number of participants
        var tickNumber = 0;
        if (new Date(earliestStartDateTime + 'Z').getMinutes() != 0) {
            //If the date doesn't start on the hour we have to set the tick number back to ensure the
            tickNumber = -1;
        }

        var xAxisBottom = d3.svg.axis()
        .scale(xScale)
        .orient("bottom")
        .tickValues(d3.range(earliestHour + 60, latestHour, 60))
        .tickSize(6, 0)
        .tickFormat(function () {

            var participants = 0;

            //Get the current time of the event tick we are up to
            var date = new Date(earliestStartDateTime + 'Z');
            date.setHours(date.getHours() + tickNumber);

            tickNumber++;

            //Count total of people who are rostered on during a given time
            roles.forEach(function (role) {

                var start_datetime_local = new Date(role.start_datetime + 'Z');
                var end_datetime_local = new Date(role.end_datetime + 'Z');

                //If we find a person with a role time between the current event times increment the participants counter by one
                if (date.getTime() >= start_datetime_local.getTime() && date.getTime() <= end_datetime_local.getTime()) {
                    participants++;
                }
            });
            return participants;
        });

        chart.append("g")
        .attr("class", "x axis buttom")
        .attr("transform", "translate(" + 0 + "," + (chartHeight) + ")")
        .call(xAxisBottom);

        // 7. Fill the roster select menu with the roles in the current event
        var uniqueRoleNames = d3.set(rolesThisPage.map(function (role) {
            return role.role_name;
        })).values();
        uniqueRoleNames.sort();

        initializeRoleFilter(uniqueRoleNames);

        // 8. Draw the bars onto the chart
        if (d3.selectAll("input[name=sort-option]")[0][0] === undefined) {
            drawRosterBars(rolesThisPage, chart, barHeight, xScale, yScale, yAxis);
        } else {
            updateSortAndFilter(roles, wrapperList, rolesPerPage, rolesFirstPage, barHeight, xScale, yScale, yAxis);
        }

        // 9. Rename the class of the SVG object to indicate that it's drawn
        d3.select(".unprocessed-chart")
        .attr("class", "chart-" + page);

        // 10. Output event info and page number (for print_roster.php only)
        var eventName = "";
        var eventDesc = "";
        var eventLoc = "";

        if (d3.select(".event_name")[0][0] !== null && d3.select(".event_name")[0][0] !== undefined) {
            eventName = d3.select(".event_name").property("value");
        }
        if (d3.select(".event_desc")[0][0] !== null && d3.select(".event_desc")[0][0] !== undefined) {
            eventDesc = d3.select(".event_desc").property("value");
        }
        if (d3.select(".event_loc")[0][0] !== null && d3.select(".event_loc")[0][0] !== undefined) {
            eventLoc = d3.select(".event_loc").property("value");
        }

        if (sort) {
            updateSortAndFilter(roles, wrapperList, rolesPerPage, rolesFirstPage, barHeight, xScale, yScale, yAxis);
        }
    }
}

function initializeRoleFilter(uniqueRoleNames) {
    var rosterSelect = d3.select("#role-filter");

    var previousVal = rosterSelect.selected;
    var listbox = rosterSelect[0][0];

    rosterSelect.innerHTML = '';

    if (listbox) {
        listbox.innerHTML = '';
    }

    rosterSelect.append("paper-item").text("All Positions").attr("value", "All Roles");

    for (var j = 0; j < uniqueRoleNames.length; j++) {
        rosterSelect.append("paper-item").text(uniqueRoleNames[j]).attr("value", uniqueRoleNames[j]);
    }

    if (!previousVal) {
        previousVal = 'All Roles';
    }

    if (listbox) {
        listbox.selected = previousVal;
    }
}

function getUniqueRoleNames(roles) {
    return [...new Set(roles.map((role) => role.role_name))]
}

function setRolesTiming(roles) {
    roles.forEach(function (role) {
        var start_datetime_local = new Date(role.start_datetime + 'Z');
        var end_datetime_local = new Date(role.end_datetime + 'Z');

        var start_datetime = new Date(start_datetime_local.getTime() + (start_datetime_local.getTimezoneOffset() * 60000));
        var end_datetime = new Date(end_datetime_local.getTime() + (end_datetime_local.getTimezoneOffset() * 60000));

        role.start_in_mins = start_datetime.getTime() / 1000 / 60;
        role.end_in_mins = end_datetime.getTime() / 1000 / 60;
    })
}
