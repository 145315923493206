$(function () {
    // placeholder, for the first time we run this AJAX to ensure
    // that our existingXHR obj contains an abort() function.
    var existingXHR = {
        abort: function(){}
    };

    // Go to group button.
    var $btnGoToGroup = $(".go-to-group");

    $btnGoToGroup.on('click', function () {
        var groupId = $("#selected_group_id").val();
        window.location.href = "/groups/view/" + groupId;
    });

    // Populate events-table dashboard widget on dropdown option change.
    $("#events_table_dd").on('selected-item-changed', function(e) {
        $('.events-table').find('.event:not(.ajax-blueprint)').remove();
        $('.no-events-message').hide();
        $('.events-table').fadeOut();
        $('.events-table-widget').find('.loading').fadeIn();

        // Cancel any previous AJAX requests.
        existingXHR.abort();

        var groupId = null;
        if (e.detail.value !== null) {
            groupId = e.detail.value.id;
            $("#selected_group_id").val(groupId);
        }

        if (groupId === null || groupId === '') {
            $('.events-table-widget').find('.loading').fadeOut();
            $btnGoToGroup.hide();
            return true;
        }

        if( groupId > 0 ) {
            $btnGoToGroup.show();
        }else {
            $btnGoToGroup.hide();
        }

        existingXHR = $.ajax({
            url: 'events/tableajax',
            type: 'POST',
            data: {group_id: groupId},
            success: function (data) {
                var event_blueprint = $('.events-table').find('.event.ajax-blueprint').clone();
                var event_admin_blueprint = $('.events-table-widget').find('.event_admin').clone();
                var new_rows = "";

                $.each(data, function(index, event) {
                    // Create a new table row by copying the <tr class="ajax-blueprint"> element.
                    var event_table_row = event_blueprint.clone();
                    event_table_row.removeClass('ajax-blueprint');
                    //change colour of row according to coverage percentage
                    var highlight_class = "";
                    if (event.stats.capacity_display > 0 && event.stats.capacity_display < 50) {
                        highlight_class = "event_coverage_poor";
                    } else if (event.stats.capacity_display >= 50 && event.stats.capacity_display < 75) {
                        highlight_class = "event_coverage_warning";
                    } else if (event.stats.capacity_display >= 75) {
                        highlight_class = "event_coverage_good";
                    }

                    // Fill the blueprint row with the ajax data.
                    event_table_row.find('.event_name a').attr('href', 'events/view/' + event.ID).text(event.event_name);
                    event_table_row.find('.event_name .location_title').text(event.description);
                    event_table_row.find('.event_name .event_start_time').text(event.start_time);
                    var event_admins = "";
                    $.each(event.admins, function(index, admin) {
                        var event_admin_div = event_admin_blueprint.clone();
                        event_admin_div.removeClass('ajax-blueprint');

                        event_admin_div.find('.toggle-admin-details').text(admin.admin_name);
                        event_admin_div.find('.admin-profile').attr('href', '/profiles/view/' + admin.admin_profile_id);

                        if (typeof admin.admin_email !== 'undefined' || admin.admin_emai.length > 0) {
                            event_admin_div.find('.admin-email').text(admin.admin_email).append('<br>');
                        } else {
                            event_admin_div.remove('.admin-email');
                        }

                        if (typeof admin.admin_mobile !== 'undefined' || admin.admin_emai.length > 0) {
                            event_admin_div.find('.admin-mobile').text(admin.admin_mobile).append('<br>');
                        } else {
                            event_admin_div.remove('.admin-mobile');
                        }

                        event_admins += event_admin_div.prop('outerHTML');
                    });
                    event_table_row.find('.event_admins').html(event_admins);
                    event_table_row.find('.event_target').text(event.stats.actual_target + " (" + event.stats.target + " Hrs)");

                    var hours_per_quantity = event.stats.target/event.stats.actual_target;
                    var quantities = parseFloat(event.stats.actual_people);

                    // 2 decimal places, unless it is a whole number.
                    quantities = quantities.toFixed(2).replace(/\.00$/, "");

                    event_table_row.find('.event_actual_fte').text(quantities + " (" + event.stats.actual_display + " hrs)");
                    event_table_row.find('.event_capped_capacity').text(event.stats.capacity_display + "%");
                    event_table_row.find('.event_roster a').attr('href', 'print_roster?id=' + event.ID);
                    //add calculated class for coverage percentage colour
                    event_table_row.find('.event_name').addClass(highlight_class);
                    event_table_row.find('.event_admins').addClass(highlight_class);
                    event_table_row.find('.event_target').addClass(highlight_class);
                    event_table_row.find('.event_actual_fte').addClass(highlight_class);
                    event_table_row.find('.event_capped_capacity').addClass(highlight_class);
                    event_table_row.find('.event_roster').addClass(highlight_class);

                    new_rows += event_table_row.prop('outerHTML');
                });

                // Append string with filled table rows to the DOM.
                $('.events-table-widget').find('.loading').fadeOut();
                if (new_rows.length) {
                    $('.events-table').append(new_rows).fadeIn();
                } else {
                    $('.no-events-message').fadeIn();
                }
            }
        });
    });

    // Toggle display of profile details (email, mobile, link to profile) when clicking on name.
    $('.events-table-widget').on('click', '.toggle-admin-details', function() {
        var this_admin_details = $(this).siblings('.admin-details');
        $('.admin-details').not(this_admin_details).hide();
        this_admin_details.toggle();
    });
});
