import LocalStorageAjax from "./localstorage-caching.module";

// Tracking when this is loaded as it is imported by webpack: included too often?
console.log('Setting up advanced search...');


// wait for iron pages to be loaded correctly if not present on load
const pageLoaded = () => {
    return new Promise((res, rej) => {
        let polymerScope = document.querySelector('dom-bind#advanced-search-polymer-scope');
        let ironPages = $('iron-pages#advanced-search-tabs');

        if(!polymerScope){
            rej();
        }

        if (ironPages.length === 0) {
            // Wait for dom-bind to be stamped
            return polymerScope.addEventListener('dom-change', () => {
                res(polymerScope);
            });
        }

        res(polymerScope);
    });
};

pageLoaded().then(polymerScope => {

    function amendEventOptions(hasLabel) {
        if (!hasLabel) {
            $('select.event_select').append("<option label='--- Select Event ---' data-entity='' value='top-label'>--- Select Event ---</option>");
            $('cbar-select2.event_select').append("<option label='--- Select Event ---' data-entity='' value='top-label'>--- Select Event ---</option>");
        }
        $('select.event_select').append("<option label='All Events' data-entity='Event' value='AllEvents'>All Events</option>");
        $('select.event_select').append("<option label='All Upcoming Events' data-entity='Event' value='AllUpcomingEvents' >All Upcoming Events</option>");

        let cbarSelect = document.querySelector('cbar-entity-select.event_select');
        if(cbarSelect){
            let headerItems = [
                {
                    id: "AllEvents",
                    title: "All Events"
                },
                {
                    id: "AllUpcomingEvents",
                    title: "All Upcoming Events"
                }
            ]
            cbarSelect.items.unshift(...headerItems);
            cbarSelect.dropdown.filteredItems = cbarSelect.dropdown.items;
        }
    }


    var currentContext; // advanced search or report

    // Get the attribute binding scope (<dom-bind>) for our Polymer elements.

    // Set the initially opened tab based on the 'entity_selection' POST variable.
    var $initialSelectedEntity = $("[name=initial_selected_entity]");

    if($initialSelectedEntity.length > 0){
        polymerScope.selectedEntity = $initialSelectedEntity.val();
    }

    $('.icon-refresh').hide();

    if ($(".content-advanced-search").length > 0) {
        currentContext = ".content-advanced-search";
    } else if ($(".content-reports").length > 0) {
        currentContext = ".content-reports";
    }

    const $eventSelect = $('cbar-entity-select.event_select');

    if($eventSelect.length > 0){
        $eventSelect.on('selected-item-changed', function (event) {
            var element = $(this);
            requestAnimationFrame(function() {
                handleEventSelect(event, element);
            })
        });
    }

    var eventsPromise = LocalStorageAjax.get('eventDropdowns', {
        url: "/events/geteventdropdowns",
        type: "GET",
    });

    eventsPromise.then(eventsResult => {
        // Format groups and add to groups select2
        const formatted = eventsResult.results.map(event => ({
            id: event.value,
            text: event.label,
        }));

        // we need to unset this here as we're amending it later, this is for correct ordering
        let optionToRemoveIndex = formatted.findIndex(event => event.id === "0");
        formatted.splice(optionToRemoveIndex, 1);

        amendEventOptions(false);

        formatted.forEach(function(option){
            $eventSelect.append('<option value=' + option.id + '>'+ option.text +'</option>');
        })
    });


    $eventSelect.on('select2:opening', function (e) {
        const $eventSelect = $('select.event_select');

        const allEvents = {
            id: 'AllEvents',
            text: 'Any Event',
        };

        $eventSelect.trigger('results:all', {
            data: {results: [allEvents]},
            query: ''
        });
    });

    const $groupOwnerSelect = $('cbar-entity-select.filter_group_owner');
    const $groupSelect = $('cbar-entity-select.group_select');


    if($groupOwnerSelect.length > 0) {
        $groupOwnerSelect.on('selected-item-changed', function (event) {
            requestAnimationFrame(() => {
                if(event.target.selected && event.target.selected.id) {
                    getEvents();
                }
            });
        });
    }

    if($groupSelect.length > 0) {
        $groupSelect.on('selected-item-changed', function (event) {
            var element = $(this);
            requestAnimationFrame(function() {
                handleEventSelect(event, element);
            })
        });
    }

    LocalStorageAjax.get('groupDropdowns', {
        url: "/groups/getgroupdropdowns",
        type: "GET",
    }).then(groupsResult => {
        // Format groups and add to groups select2
        $groupOwnerSelect[0]._removingFromAttribute = false;

        $groupOwnerSelect[0].items = groupsResult.results.map(group => ({
            id: group.value,
            title: group.label,
        }));
        $groupOwnerSelect[0].dropdown.filteredItems = $groupOwnerSelect[0].dropdown.items;
    });

    $(currentContext)
    .find(".entity a.expand")
    // Hide all of the section details by default when there is an expand button
    .each(function (index, element) {
        $(this).closest(".section").find(".section_detail").hide();
    })
    // Click handler for the expand button
    .on("click", function (event) {
        event.preventDefault();
        $(this).closest(".section").find(".section_detail").toggle();
    })
    .end()
    .find(".group_participation, .event_participation")
    // Click handler for entity member fields - edit icon
    .on("click", ".entity_member .actions a.icon-edit", function (event) {
        $('a.icon-edit').show();
        $('a.icon-delete').show();
        event.preventDefault();
        var thisEntityMember = $(this).closest(".entity_member");
        var otherEntityMembers = $(this).closest(".entity_members").find(".entity_member").not(thisEntityMember);

        thisEntityMember.find("select, input").show();
        thisEntityMember.find("multi-select-combo-box")[0].hidden = false;
        thisEntityMember.find("span.display").hide();
        otherEntityMembers.find("select, input").hide();
        otherEntityMembers.find("multi-select-combo-box")[0].hidden = true;
        otherEntityMembers.find("span.display").show();
        $(this).closest('a.icon-delete').hide();
        $(this).hide();
    })

    // Attaches a change event listener to the `filter_group_owner` and `filter_future_past` dropdowns.
    .on("change", "select.filter_group_owner, select.filter_future_past", function(){
        getEvents();
    })

    // On change handler for entity select list
    .on("change", "select.group_select, select.event_select", function (event) {
        handleEventSelect(event, $(this));
    })

    // Click handler for group member fields - remove icon
    .on("click", ".entity_member .actions a.icon-remove", function (event) {
        event.preventDefault();
        $(this).closest(".entity_member").remove();
    })
    .on("change", ".entity_member .role_id, select.role_type", function (event) {
        var textValue = $(this).find("option:selected").text();
        $(this).parent().find("span.display").text(textValue);
    })
    .on("change", ".entity_member input.start_time, .entity_member input.end_time", function (event) {
        var start_time = $(this).parent().find(".start_time").val();
        var end_time = $(this).parent().find(".end_time").val();
        var newRange;

        if (start_time.length > 0 || end_time.length > 0) {
            newRange = start_time + '-' + end_time;
        } else {
            newRange = 'Not set';
        }
        $(this).parent().find("span.display").text(newRange);
    });

    // Click handler for submit button
    $(".submit").on("click", function (event) {
        populateEntityMembersJSON();
        if (currentContext === ".content-reports") {
            event.preventDefault();
            loadReportAJAX($(this));
        }
    });

    function getEntityRoles(element, postData) {

        $.ajax({
            url: '/ajax/entity-roles',
            type: 'POST',
            data: postData,
            success: function (data) {
                if (typeof (data.data) !== 'undefined') {
                    $.each(data.data, function (index, returnElement) {
                        $(element).find(".role_id")[0].push("items", {id: returnElement.role_id, title: returnElement.display_name});
                    });
                }
            }
        });
    }


    /**
     * Retrieves and updates event data for an event management system.
     *
     * This function sends an AJAX POST request to fetch event data based on the selected filters.
     * It then updates the corresponding UI elements to display the fetched events.
     *
     * Key Features:
     * - Dynamically identifies the appropriate filter input elements (`cbar-entity-select` or standard `select`).
     * - Sends the current filter values to the server to fetch event data.
     * - Updates both traditional `<select>` dropdowns and custom `cbar-entity-select` components.
     *
     * Dependencies:
     * - jQuery library for DOM manipulation and AJAX.
     * - `amendEventOptions` function to handle additional dropdown behavior.
     *
     * @function getEvents
     */
    function getEvents() {
        let $groupInput = null;

        // Determine the appropriate group input element (custom or standard select).
        if ($("cbar-entity-select.filter_group_owner").length > 0) {
            let $groupInput = $('cbar-entity-select.filter_group_owner');
        } else {
            let $groupInput = $('select.filter_group_owner');
        }

        // Determine the appropriate event input element (custom or standard select).
        if ($("cbar-entity-select.event_select").length > 0) {
            let $eventInput = $('cbar-entity-select.event_select');
        } else {
            let $eventInput = $('select.filter_future_past');
        }

        // Prepare POST data for the AJAX request.
        const postData = {
            search_value: '',
            entity: 'event-summary',
            page: 0,
            page_size: 250,
            filter: $eventInput.val(), // Filter for future/past events.
            group: $groupInput.val() // Group filter value.
        };

        // Define default values for dropdown options (currently unused, but may serve as a fallback).
        const defaults = [
            {
                id: ''
            }
        ];

        // Display loading indicators.
        $('.icon-refresh').show();
        $('select.event_select').parent().hide();
        $('cbar-entity-select.event_select').parent().hide();

        // Send the AJAX request to fetch event data.
        $.ajax({
            url: '/ajax/entity-search',
            type: 'POST',
            data: postData,
            success: function (data) {
                // Ensure the response contains event results.
                if (typeof data.results !== 'undefined') {

                    // Clear existing options in traditional select dropdowns.
                    $('select.event_select').empty();

                    // Clear existing items in custom `cbar-entity-select` dropdowns, if present.
                    if ($('cbar-entity-select.event_select').length > 0) {
                        $('cbar-entity-select.event_select')[0].items = [];
                    }

                    // Format the results into a list of objects with `id` and `title`.
                    const formattedResults = data.results.map(result => ({
                        id: result.ID,
                        title: result.event_name
                    }));

                    // Define default options to prepend to the results (may include placeholder or reset option).
                    const defaults = [
                        {
                            id: '',
                            title: 'Select an event' // Placeholder text for dropdowns.
                        }
                    ];

                    // Populate traditional `<select>` dropdown with new options.
                    $.each(data.results, function (i, val) {
                        $('select.event_select').append(
                            `<option label='${val.event_name}' data-entity='Event' value='${val.ID}'>${val.event_name}</option>`
                        );
                    });

                    // Update custom dropdowns with formatted results.
                    $('cbar-entity-select.event_select')[0].items = formattedResults;
                    amendEventOptions(false); // Handle additional dropdown-specific logic.
                    $('cbar-entity-select.event_select')[0].dropdown.filteredItems = formattedResults;

                    // Show dropdowns after updating their content.
                    $('select.event_select').parent().show();
                    $('cbar-entity-select.event_select').parent().show();
                }

                // Hide the loading indicator.
                $('.icon-refresh').hide();
            }
        });
    }

    function handleEventSelect(event, $this){
        let dropdownValidSelected = $this[0].selected && $this[0].selected.id !== 0;

        if ($this.val() !== "0" && dropdownValidSelected) {
            var newEntityInstance = $(currentContext + getSelectedEntityClass() + " .entity_member.ajax-blueprint").clone(true).removeClass("ajax-blueprint");

            let $groupOwnerValue;
            let $groupOwnerText;
            let $entityName;

            let groupInput = $("cbar-entity-select.filter_group_owner")[0];
            $groupOwnerValue = groupInput.selected.id;
            $groupOwnerText = groupInput.selected.title;

            $entityName = $this[0].selected ? $this[0].selected.title : "";

            newEntityInstance.attr("data-id", $this[0].selected ? $this[0].selected.id : $this.val());
            newEntityInstance.attr("data-group_id", $groupOwnerValue);
            newEntityInstance.find(".entity_group .title").text($groupOwnerText);
            newEntityInstance.find(".entity_name .title").text($entityName);

            // Construct the post array that we are sending
            var $infoDiv = $this.parent().siblings('.event-inputs');

            var postData = {
                action: 'list-roles',
                source_entity: $infoDiv.children(".source_entity").val(),
                source_entity_id: $infoDiv.children(".source_entity_id").val(),
                target_entity: $infoDiv.children(".target_entity").val(),
                target_entity_id: $this[0].selected ? $this[0].selected.id : $this.val()
            };

            getEntityRoles(newEntityInstance, postData);

            newEntityInstance.appendTo(currentContext + getSelectedEntityClass() + " .entity_members");

            if($this[0].selected){
                $this[0].value = null;
            } else {
                $this[0].value = "top-label";
            }
        }
    }

    function populateEntityMembersJSON() {
        var entityMembers = $(currentContext + getSelectedEntityClass() + " .entity_member").not(".ajax-blueprint");

        var entityMembersArray = [];
        var JSONElement = ".group_members_json";

        if (currentContext === ".content-advanced-search") {
            $.each(entityMembers, function (index, element) {
                var $this = $(this);
                var $roleType = $this.find('select.role_type');

                var entityMemberObject = {
                    id: $this.attr("data-id"),
                    group_id: $this.attr("data-group_id"),
                    label: $this.find(".entity_name .title").text(),
                    role_type_id: $roleType.val(),
                    role_type_name: $roleType.find('option:selected').text(),
                    role_id: $this.find("select.role_id").val(),
                    role_name: $this.find("select.role_id option:selected").text(),
                    roles: $this.find(".role_id").val(),
                    start_time: $this.find("input.start_time").val(),
                    end_time: $this.find("input.end_time").val()
                };
                entityMembersArray.push(entityMemberObject);
            });

            if (getSelectedEntityClass() === " .event_participation") {
                JSONElement = ".event_members_json";
            }

        } else if (currentContext === ".content-reports") {
            var roleNames = [];
            $("select.role_names option:selected").each(function (index, element) {
                roleNames.push($(element).val());
            });
            entityMembersArray = {
                group_type: $(".report-criteria input[type=radio]:checked").val(),
                start_time: $("input.start_time").val(),
                end_time: $("input.end_time").val(),
                role_name: roleNames
            };
            JSONElement = "input.criteria";
        }

        $(JSONElement).val(JSON.stringify(entityMembersArray));
    }

    function loadReportAJAX(element) {
        var thisReport = $(element).closest(".report-criteria").parent();
        thisReport.find(".control.loading").fadeIn("slow");
        thisReport.find(".report-view > div").not(".ajax-blueprint").remove();

        // Construct the post array that we are sending
        var postData = {
            report: thisReport.find("input.report-name").val(),
            criteria: thisReport.find("input.criteria").val(),
        };

        $.ajax({
            url: 'ajax/reports',
            type: 'POST',
            data: postData,
            success: function (data) {
                if (typeof (data.data) !== 'undefined') {
                    populateReport(thisReport, data);
                    thisReport.find(".control.loading").fadeOut("slow");
                }
            }
        });
    }

    function getSelectedEntityClass() {
        return " ." + polymerScope.selectedEntity;
    }
}).catch(() => {});

function populateReport(report, data) {
    report.find(".report-view > div").not(".ajax-blueprint").remove();
    var newReport = report.find(".report-view .ajax-blueprint").clone().removeClass("ajax-blueprint");
    var resultBody = newReport.find("tbody");
    var resultLine = resultBody.find("tr");
    var resultFooter = newReport.find("tfoot");
    var totalsSet = false;

    $.each(data.data, function (index, criteria) {
        $.each(criteria, function (index2, entity1) {
            if (index2 !== "total") {
                resultLine.find(".entity_name a").text(entity1.total.entity_name).attr("href", "/groups/view/" + index2 + "#event-stats");
                resultLine.find(".target").text(entity1.total.target);
                resultLine.find(".actual_fte").text(Math.round(entity1.total.actual_fte * 100) / 100);
                resultLine.find(".capped_capacity").text((Math.round(entity1.total.capped_capacity * 100) / 100) + '%');
                resultBody.append(resultLine);
                resultLine = resultLine.clone();
            } else {
                resultFooter.find(".entity_name").text(entity1.total.entity_name);
                resultFooter.find(".target").text(entity1.total.target);
                resultFooter.find(".actual_fte").text(Math.round(entity1.total.actual_fte * 100) / 100);
                resultFooter.find(".capped_capacity").text((Math.round(entity1.total.capped_capacity * 100) / 100) + '%');
                totalsSet = true;
            }
        });
    });

    if (totalsSet === false) {
        resultFooter.remove();
    }
//    resultLine.remove();

    report.find(".report-view").append(newReport);

}
