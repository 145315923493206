'use strict';

import ccPHPVars from './phpconstants';
import {getUrlForRoute} from "./route";

(() => {
    // Check required fields for composing email
    if( !$('body').is('#messages') || $('#messagengn_template').length === 0 ) {
        return;
    }

    /**
     * BEGIN: JS for compose_email placeholders
     */

    const messageBodyEmail = document.querySelector('#message_body');

    const addAnotherFile = () => {
        const html = '<div class="one whole padded"><input type="file" name="upload_file[]" style="height:auto; background-color:none;" /></div>';
        $('#attachment_div').append(html);
    };

    const initEventHandlers = () => {
        // Handle changing templates
        $('#messagengn_template').on('change', function () {
            // Disable our Email fields while the template is being loaded.
            ajaxGetTemplateData(onTemplateDataRetrieved);
        });

        // Insert a token into our message body textarea.
        $('#div_placeholders').on('mousedown', '.placeholder_clickable', function () {
            const quill = messageBodyEmail.__quill;
            const tokenText = $(this).find('.placeholder_token').text();

            const newPosition = insertTextAtCurrentCaretPosition(quill, tokenText);

            const mouseUpHandler = () => {
                // When the click is complete, set the quill caret position.
                quill.setSelection(newPosition);
                this.removeEventListener('mouseup', mouseUpHandler);
            };
            this.addEventListener('mouseup', mouseUpHandler);
        });
    };

    /**
     * Inserts text into the input element at the current caret position.
     * Also triggers the change event on the element.
     * @param {Object} quill The Quill object to insert the text into.
     * @param {String} text The text to insert.
     */
    const insertTextAtCurrentCaretPosition = function (quill, text) {
        const selection = quill.getSelection();
        if (!selection) {
            return;
        }

        const insertPos = selection.index;

        // The source of the quill command (can be 'user', 'api' or 'silent'
        const source = 'user';
        quill.insertText(insertPos, text, source);

        // Return the new caret position.
        return insertPos + text.length;
    };

    /**
     * An AJAX function for getting template data.
     * @param {function} cb The callback function on success of this AJAX call.<br/>
     * The server response is passed to the callback function.
     */
    const ajaxGetTemplateData = cb => {
        $('.message_subject, .message_body_email').attr('disabled', 'disabled');

        const messagengnURL = getUrlForRoute(ccPHPVars.AJAX_ROUTE, {
            action: "messagengn"
        });

        $.ajax({
            url: messagengnURL,
            type: 'POST',
            data: {
                function: 'getTemplateData',
                template_id: $('#messagengn_template').val()
            },
            success: function (result) {
                if (typeof cb === 'function') {
                    cb(result);
                } else {
                    console.warn('No callback specified for getTemplateData()!');
                }
            },
            complete: function () {
                // Re-enable our Email fields after the AJAX call is complete.
                $('.message_subject, .message_body_email').removeAttr('disabled', '');
            }
        });
    };

    /**
     * Our callback function for getTemplateData()
     * @param {JSON} result
     */
    const onTemplateDataRetrieved = function (result) {
        // subject, body, placeholders
        if (typeof result === 'object') {
            //update subject
            $('.message_subject').val(result.subject);

            //update body
            messageBodyEmail.__quill.root.innerHTML = result.body;

            //Update cleditor to include text
            if ($('div.cleditorMain iframe').length > 0) {
                $('div.cleditorMain iframe').contents().find('body').html(result.body.replace(/\n/g, "<br />"));
            }

            let placeholders_str = '';
            if (result.placeholders !== undefined) {
                placeholders_str = '<div><h5 class="bold">Available Tokens</h5></div><hr>';
                for (let x in result.placeholders) {
                    if (result.placeholders.hasOwnProperty(x)) {
                        placeholders_str += '<div class="placeholder_clickable">\n' +
                            '<p class="placeholder_token">{{' + result.placeholders[x].placeholder + '}}</p>\n' +
                            '<p class="placeholder_description gap-left">' + result.placeholders[x].description + '</p>\n' +
                            '<p class="placeholder_default gap-left">(Default: \'' + result.placeholders[x].default_value + '\')</p>\n' +
                            '</div>';
                    }
                }
            }

            $('#div_placeholders').html(placeholders_str);
        } else {
            alert('An error occurred while attempting to load the template data');
        }
    };

    initEventHandlers();
    ajaxGetTemplateData(onTemplateDataRetrieved);

    $('#add_another_file').click(function () {
        addAnotherFile();
    });

    addAnotherFile();

    /**
     * END: JS for compose_email placeholders
     */

    $('.compose_email_form').on('submit', (event) => {
        const quill = messageBodyEmail.__quill;
        const form = $('.compose_email_form').first();
        let emptyFields = [];

        if (form.find('.message_subject').val().trim() === '') {
            emptyFields.push('subject');
        }

        if (quill.getLength() === 1) {
            // Quill length 1 means it's empty.
            emptyFields.push('body');
        }else {
            // Save body to hidden input.
            const $hiddenBodyInput = $('#message_body_email');
            $hiddenBodyInput.val(quill.root.innerHTML);
        }

        if ($.parseJSON(form.find('.selected').val()).length === 0) {
            emptyFields.push('recipients');
        }

        if (emptyFields.length > 0) {
            event.preventDefault();
            $('div.error.message').show()
                .text('The following required fields are missing: ' + emptyFields.join(', '));
            $('html, body').animate({scrollTop: 0}, 'fast');
        } else {
            $('div.error.message').hide();
        }
    });
})();
